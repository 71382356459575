import {RouteOptimizationUnassignedDetail} from "../../../generated/graphql";
import {RouteOptimizationErrorCodes} from "../../common/Constants";

export const excludeTimeWindowErrors = (details: RouteOptimizationUnassignedDetail[] | undefined) => {
  if (!details) return [];
  return details.filter(
    (detail) =>
      ![RouteOptimizationErrorCodes.TimeWindowError, RouteOptimizationErrorCodes.GroupRelationConstraint].includes(
        detail.code as any
      )
  );
};

export const extractAddressFromMessage: (input: string) => string = (input) => {
  const regex = /\[([^\]]+)\]/;
  const match = input.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return "";
  }
};
